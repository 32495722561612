<template>
	<div style="display: flex; align-items: center; gap: 10px;">
		<!-- TODO: dialog to create new option -->
		<!-- TODO: use v-model? -->
		<Field
			v-if="modelValue && options?.length"
			typeName="Ticket"
			fieldName="duration"
			:modelValue="modelValue"
			:options="options"
			:showTitle="false"
			dataCy="duration-dropdown"
		/>
		<v-icon @click="$refs.manage.open()" data-cy="edit-durations">mdi-pencil</v-icon>

		<StepperDialog ref="manage" v-model="model" v-model:stepId="step" group="DurationStep" :order="[ 'types', 'configuration' ]"
			:onConfirm="handleConfirm"
			:onCancel="handleCancel"
			:disableConfirmButton="disableConfirmButton"
		>
			<Step id="types" group="DurationStep" icon="mdi-format-list-bulleted" class="StepWithoutStepper">
				<AddButton @click="addDuration" buttonText="duration" />
				<SectionTable
					:items="options"
					:hideFooter="true"
					:columns="[ 'key', 'label', { id: '', type: 'options', width: '0%' } ]"
					no-data-text="noDurations"
					:options="[
						{ label: $t('text.delete'), icon: 'mdi-delete', action: 'delete' },
						{ label: $t('text.edit'), icon: 'mdi-pencil', action: 'edit' }
					]"
					@click-item="editDuration"
					@option-edit="editDuration"
					@option-delete="item => toggleDeleteDialog(true, item)"
					>
				</SectionTable>
			</Step>
			<Step id="configuration" group="DurationStep" :forceStatus="!!duration ? 'complete' : null" icon="mdi-cog" :disabled="!duration" class="StepWithoutStepper">
				<v-progress-linear indeterminate v-show="loading" color="green" height="8"></v-progress-linear>
				<template v-if="duration">
					<div :class="{overlayed: loading}">
						<TranslateableField typeName="Duration" fieldName="label" v-model="duration.label"
						:fieldLocale="$store.state.activeTranslation" :locales="displayedLocales"
						/>
						<br />
						<FieldSet id="generalSettingsTranslationWizard" style="padding-bottom: 20px;"></FieldSet>
						<Field typeName="Duration" fieldName="key" v-model="duration.key" :class="{ disabled: $store.state.activeTranslation != 'de' }" />
					</div>
				</template>
			</Step>

			<template #sidebar v-if="step=='configuration'">
				<LanguageSidebar :checkIfHasMissingTranslations="checkIfHasMissingTranslations" />
			</template>

			<!-- Confirm Delete -->
			<Dialog ref="deleteDialog"
				:confirmLabel="$t('text.delete')"
				:cancelLabel="$t('text.cancel')"
				:confirm-handler="onDeleteConfirmed"
				:cancel-handler="onDeleteCancelled"
				:showClose="false"
				:isDelete="true"
				:title="$t('text.delete')"
				:height="'360px'"
				:width="'540px'">
				<template #content>
					<v-row justify="center" align="center" style="padding:10px">
						<v-row justify="center" align="center" style="padding: 10px">
							<v-col class="warning-icon-col">
								<img class="warningDialogImage" src="@/assets/icons/icon-warning.svg" />
							</v-col>
							<v-col class="text-col">
								<div class="column">
									<span><b>{{$t('text.confirmDeleteAgeGroupHeader')}}</b></span>
									<span class="dialogText">{{$t('text.confirmDeleteAgeGroupText')}}</span>
								</div>
							</v-col>
						</v-row>
					</v-row>
				</template>
			</Dialog>
		</StepperDialog>
	</div>
</template>

<script lang="ts">
import SectionTable from '../../views/applications/packageDesigner/SectionTable.vue'
import LanguageSidebar from '../common/LanguageSidebar.vue'
import { loadDimensions } from './AgeGroupField.vue'
import Field from './Field.vue'
import Common from '../../mixins/Common.vue'
import StepperDialog from '../common/StepperDialog.vue'
import Step from '../../views/applications/packageDesigner/Step.vue'
import AddButton from '../../views/applications/packageDesigner/AddButton.vue'
import TranslateableField from './TranslateableField.vue'
import LanguagesNavigation from '../../mixins/LanguagesNavigation.vue'
import IfFeatureFlag from '../ifFeatureFlag/IfFeatureFlag.vue'
import Dialog from '../common/Dialog.vue'
import FieldSet from '../../views/applications/packageDesigner/FieldSet.vue'
import eventBus from '@/utils/eventBus.js'

export default {
	components: { Field, LanguageSidebar, SectionTable, StepperDialog, Step, AddButton, TranslateableField, IfFeatureFlag, Dialog, FieldSet },
	mixins: [Common, LanguagesNavigation],
	props: {
		modelValue: Object,
	},
	data: () => ({
		model: null,
		options: [],
		loading: false,
		duration: null,
		step: null,
		itemAboutToDelete: null,
	}),
	computed: {
		displayedLocales() {
			return this.languageNavigationItems?.reduce((locales, { code }) => {
				if (code !== "all" && (this.$store.state.activeTranslation === "all" || this.$store.state.activeTranslation === code)) {
					locales.push(code)
				}
				return locales
			}, [])
		},
		disableConfirmButton() {
			if (!this.duration?.key?.de) return true

			// only German should be filled in, which will fill in the default store in PEAK. Other languages are optional and will update the other stores.
			return this.duration?.label?.de === ''
		},
	},
	watch: {
		// reload options when locale changes. Otherwise we would have to reload the page to see the changes.
		serviceLocale() {
			this.loadOptions()
		},
		model() {
			this.$emit("update:modelValue", this.model)
		},
		modelValue(modelValue) {
			this.model = modelValue
		},
	},
	methods: {
		async loadOptions() {
			this.loading = true
			const clientId = this.$store.state.selectedClient.sys.id
			const r = await loadDimensions(this, clientId)
			if (r) {
				// Note: we use the German translation as fallback
				// we also need to add an id property because otherwise the Field model-driven component will set a new de object on the key property even if it is already set
				this.options = r.duration.options.map(ag => ({ ...ag, id: ag.key?.de ?? ag.key, label: ag.label }))
			}
			this.loading = false
		},
		checkIfHasMissingTranslations(locale) {
			return !this.duration?.label?.[locale]
		},
		addDuration() {
			this.duration = { id: '', key: '', label: { de: '', en: '', fr: '', it: '', nl: '' } }
			this.$refs.manage.show('configuration')
		},
		editDuration(item) {
			this.duration = JSON.parse(JSON.stringify(item))
			this.$refs.manage.show('configuration')
		},
		deleteDuration() {
			try {
				const item = this.itemAboutToDelete
				const index = this.options.findIndex(option => option.id === item.id)
				this.options.splice(index, 1)
				// persist the change
				const payload = {
					clientId: this.$store.state.selectedClient.sys.id,
					durations: this.options
				}
				this.$httpPost('/packageTravel/durations', payload)
				// TODO: what do we do for the tickets that already have this duration selected? Check in the backend and throw an error that it cannot be deleted or remove it from the existing tickets as well and assign a fallback duration as a default?
			}
			catch (e) {
				console.error(e)
			}
		},
		async handleConfirm() {
			console.log('handleConfirm', this.duration)
			if (this.step == 'configuration') {
				try {
					this.loading = true
					if (this.options.some(option => option.key.de === this.duration.key.de && option.id !== this.duration.id)) {
						console.log('duplicate key found')
						eventBus.$emit('addToastMessage', this.$t('text.duplicateDurationKey'), 'error')
						this.duration = null
						this.loading = false
						return false
					}
					if (this.duration) {
						// if the duration has an id already then we delete it from the options (to avoid an additional option being added instead of updating the existing one). If we want to update the existing one then instead of a copy by value in editDuration() we can do a copy by reference. however, that would be that we always would find a duplicate key in the check above because we are overwriting the original object in the options array..
						if (this.duration.id) {
							const index = this.options.findIndex(option => option.id === this.duration.id)
							this.options.splice(index, 1)
						}
						this.duration.id = this.duration.key.de
						this.options.push(this.duration)
					}
					const durations = this.options
					const clientId = this.$store.state.selectedClient.sys.id
					const payload = {
						clientId,
						durations
					}
					await this.$httpPost('/packageTravel/durations', payload)
					this.loading = false
					eventBus.$emit('addToastMessage', this.$t('text.changesSuccessfullyProcessed'), 'success')
					this.duration = null
				}
				catch (e) {
					console.error(e)
					eventBus.$emit('addToastMessage', this.$t('text.errorProcessingChanges'), 'error')
				}
			}
		},
		handleCancel() {
			console.log('handleCancel')
			this.duration = null
		},
		onDeleteConfirmed() {
			this.toggleDeleteDialog(false, null)
			this.deleteDuration()
			return true
		},
		onDeleteCancelled() {
			this.toggleDeleteDialog(false, null, true)
		},
		toggleDeleteDialog(val, item, reset = false) {
			this.$refs.deleteDialog.show = val
			// TODO: I did not like this solution but I could not find a better one. The item is not passed to the dialog so we need to store it in a variable to be able to access it in the deleteAgeGroup method
			if (item) this.itemAboutToDelete = item
			if (reset) this.itemAboutToDelete = null
		},
	},
	async mounted() {
		this.model = this.modelValue
		await this.loadOptions()
	},
}
</script>

<style scoped lang="scss">
.disabled {
	pointer-events: none !important;
	opacity: 0.5 !important;
}
.overlayed {
	pointer-events: none;
	&::before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(255, 255, 255, 0.5);
		z-index: 99;
	}
}
.column {
	display: flex;
	flex-direction: column;
}
.StepWithoutStepper {
	margin-top: 0px;
	position: relative;
}
</style>